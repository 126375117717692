<template>
  <div class="hot-issue-details">
    <div class="title">
      {{$route.query.data.title}}
    </div>
    <div class="issue-details">
      <span>浏览次数：6562次</span><span>发布时间：2021-12-01</span>
    </div>
    <div class="news">
      {{$route.query.data.content}}
    </div>
  </div>
</template>

<script>
export default {
  name: "OperationDetails",

}

</script>

<style lang="scss" scoped>
.hot-issue-details {

  .title {
    height: 2.125rem;
    font-size: 1.5rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 2.125rem;
    padding-bottom: 0.625rem;
  }

  .issue-details {
    height: 1.375rem;
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 1.1875rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #EDEFF2;
    margin-bottom: 2rem;

    span:first-child {
      margin-right: 0.75rem;
    }
  }

  .news {
    text-align: left;
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E454D;
    line-height: 2rem;
    letter-spacing: 0.125rem;
  }
}
</style>